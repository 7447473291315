import styled, {css} from 'styled-components'
import space from '../../styles/space'
import {basicallyBlack} from '../../styles/colors'
import borderRadius from '../../styles/borderRadius'
import hexToRGB from '../../utils/hexToRGB'

const Image = styled.img`
  flex-grow: 1;
  box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);

  ${({width}) => width && css`
    width: ${width}px;
    height: auto;
  `}

  ${({br = true}) => br && borderRadius}

  ${({flipX}) => flipX && `transform: scaleX(-1);`}
`

export default Image