import React from 'react'
import { motion, } from "framer-motion";
import {quaternaryTitleFont} from '../../styles/fonts'
import styled from 'styled-components'
import space from '../../styles/space'
import {basicallyBlack} from '../../styles/colors'
import borderRadius from '../../styles/borderRadius'
import hexToRGB from '../../utils/hexToRGB'
import AnimateIn from './AnimateIn'
import breakpoints from '../../styles/breakpoints';
import {connect} from 'react-redux'
import DoubleParallax from './DoubleParallax';
import { Link } from "gatsby";

const StyledImage = styled(motion.img)`
  width: 100%;
  cursor: pointer;
  z-index: 2;
  max-width: 798px;
  flex-grow: 1;
  box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
  ${({br = true}) => br && borderRadius}
  
  ${breakpoints.lg} {
    ${({index}) => index % 2 ? (`
      margin-right: auto;
    `) : (`
      margin-left: auto;
    `)};
  }
` 

const StyledProjectTitle = styled.h2`
  font-family: ${quaternaryTitleFont};
  font-weight: 900;
  /* width: 350px; */
  width: 100%;
  z-index: 3;
  display: inline-block;
  padding-top: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;

  ${breakpoints.lg} {
    background-color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryColor};
    font-size: 3rem;
    width: 350px;
    padding: 1rem 1rem;
    ${({yOffset}) => yOffset && `
      margin-top: 14rem;
    `}

    ${({index}) => !(index % 2 === 0) ? `
      margin-right: -6rem;
    ` : `
      margin-left: -6rem;
    `}
  }
`

const StyledProject = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  flex-direction: column;
  margin-bottom: 5rem;
  position: relative;
  user-select: none;

  ${breakpoints.lg} {
    margin-bottom: 15rem;

    ${({yOffset}) => yOffset && `
      position: absolute;
      bottom: 10%;
    `}

    ${({index}) => !(index % 2 === 0) ? `
      flex-direction: row-reverse; 
    ` : `
      flex-direction: row;
    `}
  }
`

const DesktopProject = styled(StyledProject)`
  display: none;
  
  ${breakpoints.lg} {
    display: flex;
  }
`

const MobileProject = styled(StyledProject)`
  display: flex;

  ${breakpoints.lg} {
    display: none;
  }
`

const Project = ({landscape, index, reduxTheme, mouse, scroll, frontmatter: {title, date, featuredImg}}) => (
  <>
    <DesktopProject reduxTheme={reduxTheme} index={index}>
      <DoubleParallax index={index} offset={400} weight={20} mouse={mouse} scroll={scroll}>
        <StyledImage src={featuredImg}  index={index} landscape={landscape} />
      </DoubleParallax>
    
      <DoubleParallax index={index} offset={700} weight={10} weightScroll={7} weightX={10} mouse={mouse} scroll={scroll}>
        <StyledProjectTitle yOffset index={index} reduxTheme={reduxTheme}>
          {title}
        </StyledProjectTitle>
      </DoubleParallax>
    </DesktopProject>
    <MobileProject reduxTheme={reduxTheme} index={index}>
      <StyledImage src={featuredImg}  index={index} landscape={landscape} />
  
      <StyledProjectTitle index={index} reduxTheme={reduxTheme}>
        {title}
      </StyledProjectTitle>
    </MobileProject>
  </>
)

const FeaturedProject = ({ fields, ...rest}) => {
  if(!rest || !rest.frontmatter)
    return null;

  const link = rest.frontmatter.path;

  return (
    <AnimateIn>
      {link ? (
        <Link to={link}>
          <Project {...rest} />
        </Link>
      ): (
        <Project {...rest} />
      )}
    </AnimateIn>
  );
};
export default connect(({theme}) => ({reduxTheme: theme}))(FeaturedProject)