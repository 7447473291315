import React, { useState, useRef, useLayoutEffect } from 'react'
import { motion, useTransform, useMotionValue } from "framer-motion";
import {duration} from '../../styles/animation'

const DoubleParallax = ({ 
  offsetTop,
  yMin,
  yMax,
  xMin,
  xMax,
  className, 
  weight=1,
  weightX,
  weightScroll,
  depth,
  offset = 1000,
  children,
  mouse = {x: 0, y: 0},
  scroll = {},
  index, 
}) => {
  // const [elementTop, setElementTop] = useState(0);
  // const [element, setElement] = useState({});
  // const [boundingClient, setBoundingClient] = useState({});
  const outerRef = useRef(null);
  // const innerRef = useRef(null);
  const { scrollY } = scroll;
  // const { view, ...mouse} = mouse;
  const yMouse = useMotionValue(mouse ? mouse.y : 0);
  const xMouse = useMotionValue(mouse ? mouse.x : 0);

  xMouse.set(mouse ? mouse.x : 0)
  yMouse.set(mouse ? mouse.y : 0)
  
  // const y = useTransform(
  //   scrollY, 
  //   [0, elementTop + offset], 
  //   ['0px', offset/2+'px'],
  //   { clamp: false }
  // );
  // const top = boundingClient.top + scrollY.get();
  // const scrollBounds = [top - view.y[1], top + element.offsetHeight];
  
  // const scrollWeight = weightScroll || weight;
  // const yScrollOffset = useTransform(
  //   scrollY, 
  //   scrollBounds, 
  //   [((offset)/scrollWeight) + 'px', (offset)/scrollWeight + '0px'],
  //   // { clamp: false }
  // );

  const _yMouseOffset = useTransform(
    yMouse, 
    mouse.view.y,
    [(offset/weight) + 'px', -(offset/weight) + 'px'],
  );

  const xWeight = weightX || weight;
  const x = useTransform(
    xMouse, 
    mouse.view.x, 
    [offset/xWeight + 'px', -offset/xWeight + 'px'],
  );

  useLayoutEffect(() => {
    // const {offsetTop, offsetLeft, offsetRight, offsetHeight} = outerRef.current;
    // setElementTop(offsetTop);
    // setElement({offsetTop, offsetLeft, offsetRight, offsetHeight});
    // setBoundingClient(outerRef.current.getBoundingClientRect());
  }, [outerRef]);

  if(!mouse || !mouse.view)
    return null;
  
  else if(mouse.view.x[1] < 768)
    return children

  else
    return (
      <motion.div 
        transition={{ duration: duration.md }}
        ref={outerRef} 
        key={'outer' + index}
          style={{y: _yMouseOffset, x, z: '-1px', zIndex: '0'}} 
          // style={{y: yScrollOffset, z: '-1px', zIndex: '0'}} 
        className={className}>
        {/* <motion.div 
          transition={{ duration: duration.md }}
          ref={innerRef} 
          style={{y: _yMouseOffset, x, z: '-1px', zIndex: '0'}} 
          className={className}> */}
          {children}
        {/* </motion.div> */}
      </motion.div>
    );
};

DoubleParallax.defaultProps = { 
  offsetTop: 1000,
  className: '', 
  weight: 1,
  depth: 0,
}

export default DoubleParallax  