import { useEffect, useState } from "react";
import { window, document, exists } from 'browser-monads';

const useMousePosition = () => {
  // const [position, setPosition] = useState({ x: 0, y: 0 });

  const [position, setPosition] = useState({ 
    x: 0, 
    y: 0, 
    fromCenter: {
      x: 0, y: 0
    },
    view: {
      x: [0, window.innerWidth ],
      y: [0, window.innerHeight ],
    }});

    
  useEffect(() => {
    const setFromEvent = e => {
      const {clientX, clientY, view} = e;
      const {innerWidth, innerHeight} = view;

      setPosition({ 
        x: clientX,
        y: clientY, 
        fromCenter: {
          x: (innerWidth/2) - clientX,
          y: (innerHeight/2) - clientY,
        }, 
        view: {
          x: [0, innerWidth],
          y: [0, innerHeight]
        }
      });
    } 

      window.addEventListener("mousemove", setFromEvent);
    

    return () => {
        window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  return position;
};

export default useMousePosition