import styled, {css} from 'styled-components'
import breakpoints from '../../styles/breakpoints'

const Col = styled.div`
  position: relative;
  flex-grow: 1;

  ${({center}) => center && css`
    text-align: center;
  `}

  ${({align}) => align && css`
    text-align: ${align};
  `}

  ${({flex}) => flex && css`
    display: flex;
    flex-direction: column;
  `}

  
  ${({xs}) => xs && css`
    width: calc(100% * ${xs});
  `}
  
  ${({sm}) => sm && css`
    ${breakpoints.sm} {
      width: calc(100% * ${sm});
    }
  `}

  ${({md}) => md && css`
    ${breakpoints.md} {
      width: calc(100% * ${md});
    }
  `}

  ${({lg}) => lg && css`
    ${breakpoints.lg} {
      width: calc(100% * ${lg});
    }
  `}

  ${({xl}) => xl && css`
    ${breakpoints.xl} {
      width: calc(100% * ${xl});
    }
  `}
`

export default Col