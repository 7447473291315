import React from "react";
import {motion} from "framer-motion"
import styled from "styled-components"
import Container from '../components/styled/Container'
import Row from '../components/styled/Row'
import Col from '../components/styled/Col'
import { NAV_HEIGHT } from "../components/Nav";
import {animateChildren, inAndUp, fadeIn, extraLong} from '../styles/animation'
import space from '../styles/space'
import breakpoints from '../styles/breakpoints'
import Portrait from '../components/Portrait'
import projects from '../config/projects.config'
import FeaturedProjects from '../components/FeaturedProjects'
import Section from '../components/styled/Section'
import {Link} from 'gatsby'
import FeaturedProject from '../components/motion/FeaturedProject'
import useMousePosition from "../utils/hooks/useMousePosition";
import { useViewportScroll } from "framer-motion";

const TestPage = () => {
  const mouse = useMousePosition();
  const scroll = useViewportScroll();
  
  return (
    <Container>
      <Section center responsive align='center'>
        <Col>                  
          <FeaturedProject mouse={mouse} scroll={scroll} key={0} {...projects[0]} index={0}/>
          <FeaturedProject mouse={mouse} scroll={scroll} key={1} {...projects[1]} index={1}/>
        </Col>
      </Section>
    </Container>
  );
}

export default TestPage;
