import styled from "styled-components"
import Row from './Row'
import space from '../../styles/space'
import breakpoints from '../../styles/breakpoints'

const StyledRow = styled(Row)`
  margin-top: ${space.md}px;

  ${breakpoints.md} {
    margin-top: ${space.xl}px;
  }

  ${breakpoints.lg} {
    margin-top: ${space.xxl}px;
  }
`

export default StyledRow