const projects = [{
  title: 'Lightstep Real Time Status Page',
  landscape: true,
  src: 'https://placekitten.com/g/600/400',
  link: '/lightstep',
}, {
  title: 'Grandy',
  landscape: true,
  src: 'https://placekitten.com/g/600/400',
  link: '/grandy',
}, {
  title: 'Majorkey',
  landscape: true,
  src: 'https://placekitten.com/g/600/400',
  link: '/test',
},{
  title: 'Evan 360',
  landscape: true,
  src: 'https://placekitten.com/g/600/400',
  link: '/',
},{
  title: 'Good Today',
  src: 'https://placekitten.com/g/525/400',
  link: '/',
},{
  title: 'XYZ Atlas',
  src: 'https://placekitten.com/g/525/400',
  link: '/',
}]

export default projects