import React from 'react'
import styled from 'styled-components'
import Row from './styled/Row'
import Col from './styled/Col'
import FeaturedProject from './motion/FeaturedProject'
import space from '../styles/space'
import { breakpoints } from '../styles/breakpoints';
import LazyLoad from 'react-lazyload'

const StyledFeaturedProjects = styled.div`
  transform: translateZ(1px);
  padding-bottom: ${space.xl}px;
  padding-bottom: ${space.xl}px;

  ${breakpoints.lg} {
    padding-top: 0px;
  }
`

const StyledCol = styled(Col)`
  ${breakpoints.lg} {
    text-align: ${({index}) => index % 2 ? 'left': 'right'};
  }
`

const FeaturedProjects = ({mouse, scroll, projects}) => {
  return (
    <StyledFeaturedProjects>
      {projects.map((project, i) => {
        // const justify = mouse && mouse.view && mouse.view.x[1] >
          return (
            <Row key={`${project.frontmatter.path} ${i*i}`} justify={(i % 2) ? 'flex-end' : 'flex-start'}>
              <StyledCol flex index={i}>
                {i === 0 ? (
                  <FeaturedProject
                    {...project} 
                    key={i}
                    index={i} 
                    mouse={mouse} scroll={scroll} />
                ) : (
                  <LazyLoad height={project.landscape ? 400 :525} offset={100}>
                    <FeaturedProject
                      {...project} 
                      key={i}
                      index={i} 
                      mouse={mouse} scroll={scroll} />
                  </LazyLoad>
                )}
              </StyledCol>
            </Row>
          )
        }
      )}
    </StyledFeaturedProjects>
  )
}

export default FeaturedProjects