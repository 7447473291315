import styled, {css} from 'styled-components'
import breakpoints from '../../styles/breakpoints'

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* Align-items */

  /* If responsive */
  ${({center, align, justify}) => center ? css`
    align-items: center;
    justify-content: center;
  ` : css`
    align-items: ${align || 'flex-start'};
    justify-content: ${justify || 'flex-start'};
  `}
  
  /* If responsive */
  ${({bp, responsive}) => responsive && css`
    flex-direction: ${({reverse}) => !reverse && 'column'};

    ${breakpoints[bp || 'md']} {
      flex-direction: ${({reverse}) => !reverse && 'row'};
    }
  `}
`

export default Row